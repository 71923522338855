<template>
  <div class="animated fadeIn">
    <b-card>
      <b-tabs content-class="mt-3" justified>
        <b-tab title="Overview" active>
          <form>
            <h5>
              Invoice details
              <b-spinner v-if="isLoading" small type="grow" class="mb-1" />
            </h5>

            <hr />

            <b-row>
              <b-col xl="3" lg="6" md="6" sm="12" xs="12">
                <inline-input
                  :id="controls.subject.id"
                  :value="data.subject"
                  :label="controls.subject.label"
                  :readonly="false"
                  :mode="mode"
                  :required="true"
                  @changed="updateField"
                />
              </b-col>
              <b-col xl="3" lg="6" md="6" sm="12" xs="12">
                <inline-date-picker
                  :id="controls.invoice_date.id"
                  :value-single="data.invoice_date"
                  :label="controls.invoice_date.label"
                  :readonly="controls.invoice_date.readonly"
                  :mode="mode"
                  @changed="updateDateField"
                />
              </b-col>
              <b-col xl="3" lg="6" md="6" sm="12" xs="12">
                <inline-select
                  :id="controls.assigned_to.id"
                  :value="data.assigned_to"
                  :label="controls.assigned_to.label"
                  :allow-empty="false"
                  :readonly="false"
                  :options="controls.assigned_to.options"
                  :mode="mode"
                  :required="true"
                  @changed="updateSelect"
                />
              </b-col>

              <b-col xl="3" lg="6" md="6" sm="12" xs="12">
                <inline-input
                  :id="controls.invoice_no.id"
                  :value="data.invoice_no"
                  :label="controls.invoice_no.label"
                  :readonly="true"
                  :mode="mode"
                  :required="false"
                  @changed="updateField"
                />
              </b-col>
              <b-col xl="3" lg="6" md="6" sm="12" xs="12">
                <inline-select
                  :id="controls.account.id"
                  :link="controls.account.link"
                  :value="data.account"
                  :label="controls.account.label"
                  :allow-empty="false"
                  :readonly="false"
                  :options="controls.account.options"
                  :mode="mode"
                  :link-mode="true"
                  :loading="controls.account.loading"
                  :async="true"
                  @async-search="onAccountSearch"
                  @link-clicked="onAccountLinkClicked"
                  :required="true"
                  @changed="updateSelect"
                />
              </b-col>
              <b-col xl="3" lg="6" md="6" sm="12" xs="12">
                <inline-input
                  :id="controls.modified_time.id"
                  :value="data.modified_time"
                  :label="controls.modified_time.label"
                  :readonly="true"
                  :mode="mode"
                  :required="false"
                  @changed="updateField"
                />
              </b-col>

              <b-col lg="6" md="6" sm="12" xs="12">
                <inline-input
                  :id="controls.description.id"
                  :value="data.description"
                  :label="controls.description.label"
                  :readonly="false"
                  :mode="mode"
                  :required="false"
                  @changed="updateField"
                />
              </b-col>
            </b-row>

            <h5>Address details</h5>
            <hr />
            <b-row>
              <b-col lg="3" md="3">
                <inline-input
                  :id="controls.street.id"
                  :value="data.street"
                  :label="controls.street.label"
                  :readonly="false"
                  :mode="mode"
                  :required="false"
                  @changed="updateField"
                />
              </b-col>

              <b-col lg="3" md="3">
                <inline-select
                  :id="controls.country.id"
                  :value="data.country"
                  :label="controls.country.label"
                  :readonly="controls.country.readonly"
                  :options="controls.country.options"
                  :allow-empty="false"
                  :mode="mode"
                  @changed="updateSelect"
                  :required="true"
                />
              </b-col>

              <b-col lg="3" md="3">
                <inline-select
                  :id="controls.state.id"
                  :value="data.state"
                  :label="controls.state.label"
                  :readonly="controls.state.readonly"
                  :options="controls.state.options"
                  :allow-empty="false"
                  :taggable="true"
                  :mode="mode"
                  @changed="updateSelect"
                  :required="true"
                />
              </b-col>
              <b-col lg="3" md="3">
                <inline-select
                  :id="controls.city.id"
                  :value="data.city"
                  :label="controls.city.label"
                  :readonly="controls.city.readonly"
                  :options="controls.city.options"
                  :allow-empty="false"
                  :taggable="true"
                  :mode="mode"
                  @changed="updateSelect"
                  :required="true"
                />
              </b-col>
            </b-row>

            <b-row>
              <b-col lg="3" md="3">
                <inline-input
                  :id="controls.postal_code.id"
                  :value="data.postal_code"
                  :label="controls.postal_code.label"
                  :readonly="false"
                  :mode="mode"
                  :required="false"
                  @changed="updateField"
                />
              </b-col>
              <b-col lg="3" md="3">
                <inline-input
                  :id="controls.pobox.id"
                  :value="data.pobox"
                  :label="controls.pobox.label"
                  :readonly="false"
                  :mode="mode"
                  :required="false"
                  @changed="updateField"
                />
              </b-col>
            </b-row>

            <hr />
            <h5>Item details</h5>
            <hr />
            <b-row>
              <b-col lg="4">
                <b-button-group class="p-2">
                  <button
                    class="btn btn-outline-dark btn"
                    type="button"
                    :disabled="dataTable.isInserting"
                    @click="addItem()"
                    title="Add product"
                  >
                    <font-awesome-icon icon="plus" />
                  </button>
                </b-button-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <div id="card">
                  <v-client-table
                    ref="dataTable"
                    name="client_table_invoice_submission"
                    v-if="dataTable.dataSet.length"
                    @filter="onFilter"
                    @update="onInlineTableUpdate"
                    v-model="dataTable.dataSet"
                    :options="dataTable.options"
                    :columns="dataTable.columns"
                  >
                    <template
                      v-for="(column, index) in dataTable.formatColumns"
                    >
                      <span
                        :key="`el-${index}`"
                        :slot="column.name"
                        slot-scope="props"
                        class="default-cell"
                      >
                        <strong
                          v-if="props.row[column.name] === 'Invoice Total:'"
                          >{{
                            (
                              parseFloat(props.row[column.name]) ||
                              props.row[column.name]
                            ).toLocaleString('en-US', column.style)
                          }}</strong
                        >
                        <span v-if="props.row[column.name] !== 'Invoice Total:'"
                          >{{
                            (
                              parseFloat(props.row[column.name]) ||
                              props.row[column.name]
                            ).toLocaleString('en-US', column.style)
                          }}
                        </span>
                      </span>
                    </template>

                    <template v-for="roColumnName in readOnlyColumns">
                      <slot
                        :name="roColumnName"
                        :slot="roColumnName"
                        slot-scope="{ row }"
                      >
                        <div :key="`${$helpers.uuidv4()}`" class="default-cell">
                          {{ row[roColumnName] }}
                        </div>
                      </slot>
                    </template>
                    <template
                      v-for="(column, index) in dataTable.options
                        .editableColumns"
                      :slot="column"
                      slot-scope="{
                        row,
                        update,
                        setEditing,
                        isEditing,
                        revertValue
                      }"
                    >
                      <span
                        :key="`dts1-${index}`"
                        @click="setEditing(true)"
                        v-if="!isEditing()"
                        class="default-cell"
                        >{{ row[column] }}</span
                      >
                      <span
                        :key="`dts2-${index}`"
                        @click="setEditing(true)"
                        v-if="!isEditing() && !row[column]"
                        class="pl-5"
                      >
                        <b-img
                      /></span>

                      <span
                        :key="`dts3-${index}`"
                        class="d-flex flex-row align-items-center default-cell"
                        v-if="isEditing()"
                      >
                        <b-form-input
                          v-if="isEditing() && !dropdown(column)"
                          style="width: 150px"
                          type="number"
                          v-model="row[column]"
                          @input="
                            onColumnUpdate(row['ID'], column, row[column])
                          "
                        />
                        <multiselect
                          :id="row['ID'] + ':' + column"
                          v-if="isEditing() && dropdown(column)"
                          track-by="id"
                          label="label"
                          :value="rowDropdown(row, column).value"
                          @input="onChangeSelect"
                          @open="onItemDropdownOpen"
                          @close="onItemDropdownClose"
                          :options="dropdown(column).options"
                          :show-labels="false"
                          placeholder=""
                          :multiple="dropdown(column).multiple"
                          :allow-empty="dropdown(column).allowEmpty"
                        />

                        <span
                          class="btn btn-success btn-sm ml-2"
                          @click="
                            if (validateCell(column, row[column])) {
                              update(row[column])
                              setEditing(false)
                            }
                          "
                          v-if="!isNewRecord(row)"
                        >
                          <font-awesome-icon icon="check"
                        /></span>
                        <span
                          class="btn btn-danger btn-sm"
                          @click="
                            revertValue()
                            setEditing(false)
                          "
                          v-if="!isNewRecord(row)"
                        >
                          <font-awesome-icon icon="ban"
                        /></span>
                      </span>
                    </template>

                    <div slot="Actions" slot-scope="props">
                      <clip-loader
                        class="mt-1"
                        :size="14"
                        color="#36D7B7"
                        :loading="
                          isNewRecord(props.row) &&
                          props.row.isInserting === true
                        "
                      />
                      <div
                        class="btn-group"
                        v-if="isNewRecord(props.row) && !props.row.isInserting"
                      >
                        <button
                          class="btn btn-success btn-sm"
                          @click="saveNewRecord(props.row)"
                        >
                          <font-awesome-icon icon="check" />
                        </button>
                        <button
                          class="btn btn-danger btn-sm"
                          @click="revertNewRecord"
                        >
                          <font-awesome-icon icon="ban" />
                        </button>
                      </div>

                      <button
                        v-if="
                          props.row.ID !== 'Total_ID' && !isNewRecord(props.row)
                        "
                        class="btn btn-danger btn-sm"
                        @click="deleteItem(props.row.ID)"
                      >
                        <font-awesome-icon icon="trash" />
                      </button>
                    </div>
                  </v-client-table>
                </div>
              </b-col>
            </b-row>
            <!--
                <b-container>
                <b-row align-h="end">
                    <b-col cols="2"> <strong>Invoice Total:</strong></b-col>
                    <b-col cols="2"> <span>$123</span></b-col>
                </b-row>
                </b-container>
                -->
          </form>
        </b-tab>
        <b-tab
          :title="controls.tabs.files.title"
          v-if="mode !== $constants.FORM_MODE.CREATE"
        >
          <files-container
            :module-id="$route.meta.module.id"
            :entity-id="id"
            @loaded="onFileContainerLoad"
          />
        </b-tab>
      </b-tabs>

      <hr />

      <form-submission-actions
        :mode="mode"
        :loading="{ save: saveInProgress, saveAndView: saveAndViewInProgress }"
        :buttons-visibility="{
          previous: $customTable.getPrevKey($route.meta.module, id),
          next: $customTable.getNextKey($route.meta.module, id)
        }"
        @previous-item="
          $router.push({
            name: $route.name,
            params: {
              action: $route.params.action,
              id: $customTable.getPrevKey($route.meta.module, id)
            }
          })
        "
        @next-item="
          $router.push({
            name: $route.name,
            params: {
              action: $route.params.action,
              id: $customTable.getNextKey($route.meta.module, id)
            }
          })
        "
        @save="
          save('tabular').then(response =>
            response ? $router.push({ name: 'Invoices' }) : false
          )
        "
        @save-and-view="
          save('view').then(response =>
            response
              ? $router.push({
                  name: 'Invoice submission',
                  params: { action: 'view', id: response }
                })
              : false
          )
        "
        @edit="
          $router.push({
            name: 'Invoice submission',
            params: { action: 'edit', id: id }
          })
        "
        @back="$router.push($store.getters['router/previousRoute'])"
      />
    </b-card>

    <b-modal
      ref="account-preview-modal"
      centered
      scrollable
      size="lg"
      :title="accountPreviewModal.title"
    >
      <b-container fluid class="ma-0 pa-0" style="height: 80vh">
        <AccountSubmissionForm :id="accountPreviewModal.id" action="preview" />
      </b-container>

      <template #modal-footer="">
        <hr />
        <div class="form-row d-flex justify-content-end">
          <b-button
            variant="outline-dark"
            class="m-1"
            @click="openAccountInNewWindow(accountPreviewModal.id)"
          >
            <font-awesome-icon icon="share" /> Open in new window
          </b-button>
          <b-button
            variant="outline-dark"
            class="m-1"
            @click="closeAccountPreview()"
          >
            <font-awesome-icon icon="times" /> Close
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue'
import moment from 'moment'

import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'

import AccountSubmissionForm from '@/views/RelationshipManagement/AccountSubmissionForm/index.js'

import { mapState, mapActions } from 'vuex'

export default {
  name: 'InvoiceSubmissionForm',
  props: {
    id: {
      type: String,
      default: ''
    },
    action: {
      type: String,
      default: ''
    }
  },
  components: {
    Multiselect,
    AccountSubmissionForm
  },
  data: function () {
    return {
      mode: undefined,
      isDropdownOpen: false,
      baseApiUrl: 'invoices',
      isLoading: false,
      saveInProgress: false,
      saveAndViewInProgress: false,
      accountPreviewModal: {
        id: '',
        title: ''
      },
      controls: {
        tabs: {
          files: {
            title: 'Files'
          }
        },
        buttons: {
          edit: {
            id: 'button:details_edit'
          },
          save: {
            id: 'button:details_save'
          }
        },
        subject: {
          id: 'input:subject',
          label: 'Subject',
          changed: false,
          readonly: false
        },
        invoice_date: {
          id: 'datepicker:invoice_date',
          label: 'Invoice Date',
          changed: false,
          readonly: false
        },
        modified_time: {
          id: 'input:modified_time',
          label: 'Modified Time',
          changed: false,
          readonly: false
        },
        invoice_no: {
          id: 'input:invoice_no',
          label: 'Invoice No',
          changed: false,
          readonly: true
        },
        assigned_to: {
          id: 'select:assigned_to',
          label: 'Assigned To',
          changed: false,
          readonly: false,
          options: []
        },
        account: {
          id: 'select:account',
          label: 'Account',
          changed: false,
          readonly: false,
          options: [],
          loading: false
        },
        description: {
          id: 'input:description',
          label: 'Description',
          changed: false,
          readonly: false
        },
        street: {
          id: 'input:street',
          label: 'Invoice Address',
          changed: false,
          readonly: false
        },
        country: {
          id: 'select:country',
          label: 'Invoice Country',
          options: [],
          changed: false,
          readonly: false
        },
        state: {
          id: 'select:state',
          label: 'Invoice State',
          options: [],
          changed: false,
          readonly: false
        },
        city: {
          id: 'select:city',
          label: 'Invoice City',
          options: [],
          changed: false,
          readonly: false
        },
        postal_code: {
          id: 'input:postal_code',
          label: 'Invoice Postal Code',
          changed: false,
          readonly: false
        },
        pobox: {
          id: 'input:pobox',
          label: 'Invoice PO Box',
          changed: false,
          readonly: false
        }
      },
      data: {
        id: '',
        subject: '',
        invoice_date: '',
        modified_date: '',
        assigned_to: {
          id: '',
          label: ''
        },
        account: {
          id: '',
          label: ''
        },
        description: '',
        street: '',
        country: {
          id: '',
          label: ''
        },
        state: {
          id: '',
          label: ''
        },
        city: {
          id: '',
          label: ''
        },
        postal_code: '',
        pobox: '',
        products: []
      },
      dataTable: {
        isLoading: false,
        options: {
          uniqueKey: 'ID',
          filterByColumn: false,
          showChildRowToggler: false,
          filterable: false,
          editableColumns: ['Product Name', 'Quantity'],
          perPage: 10,
          perPageValues: [],
          saveState: true,
          storage: 'local'
        },
        columns: ['Product Name', 'Quantity', 'Price', 'Total', 'Actions'],
        formatColumns: [
          {
            name: 'Quantity',
            style: {
              style: 'decimal'
            }
          },
          {
            name: 'Price',
            style: {
              style: 'currency',
              currency: 'USD'
            }
          },
          {
            name: 'Total',
            style: {
              style: 'currency',
              currency: 'USD'
            }
          }
        ],
        dataSet: [],
        totalRecords: 0,
        isInserting: false,
        dropdowns: [
          {
            name: 'Product Name',
            options: [],
            value: [],
            multiple: false,
            allowEmpty: false
          }
        ]
      },
      districts: []
    }
  },
  computed: {
    ...mapState({
      profile: state => state.profile
    }),
    readOnlyColumns () {
      return ['Price', 'Total', 'Actions']
    }
  },
  created () {
    this.initialize()
  },
  mounted () {},
  methods: {
    ...mapActions('profile', ['fetchProfile']),
    onAccountSearch (payload) {
      let self = this

      self.controls.account.loading = true

      this.$api
        .post('dictionaries/accounts', {
          query: payload.query,
          starts_with: payload.startsWith
        })
        .then(response => {
          self.controls.account.loading = false

          self.controls.account.options = response.map(u => ({
            id: u.id,
            label: u.name
          }))
        })
    },
    onAccountLinkClicked (e) {
      this.accountPreviewModal.id = e.id
      this.accountPreviewModal.title = `Account ${e.label}`

      this.$refs['account-preview-modal'].show()
    },
    openAccountPreview () {
      this.$refs['account-preview-modal'].show()
    },
    closeAccountPreview: function () {
      this.$refs['account-preview-modal'].hide()
    },
    openAccountInNewWindow (account_id) {
      let routeData = this.$router.resolve({
        name: 'Account submission',
        params: {
          id: account_id,
          action: 'view'
        }
      })
      window.open(routeData.href, '_blank')
    },
    onFileContainerLoad (count) {
      if (count > 0) this.controls.tabs.files.title = `Files (${count})`
    },
    async initialize () {
      if (this.action === 'create') {
        this.mode = this.$constants.FORM_MODE.CREATE
      }
      if (this.action === 'edit') {
        this.mode = this.$constants.FORM_MODE.EDIT
      }
      if (this.action === 'view') {
        this.mode = this.$constants.FORM_MODE.VIEW
      }

      this.data.id = this.id

      this.fetchDropdowns()

      if (this.mode !== this.$constants.FORM_MODE.CREATE && this.id) {
        this.fetchData(this.id)
      }

      if (this.mode !== this.$constants.FORM_MODE.CREATE && this.id) {
        this.drawDataTable(this.id)
      }

      if (this.mode === this.$constants.FORM_MODE.CREATE) {
        this.data.invoice_date = moment.utc().format('YYYY-MM-DD')

        this.dataTable.dataSet.push({
          ID: 'Total_ID',
          Price: 'Invoice Total:',
          Total: 0
        })
      }
    },
    isNewRecord (row) {
      if (!row) return false

      return row['ID'] === this.$constants.CUSTOM_TABLE.NEW_ROW_ID
    },
    dropdown (_name) {
      return this.dataTable.dropdowns.find(n => n.name === _name)
    },
    rowDropdown (_row, _name) {
      return _row.dropdowns.find(d => d.name === _name)
    },
    fetchDropdowns () {
      let self = this

      this.$api.get('users').then(response => {
        self.controls.assigned_to.options = response.map(u => ({
          id: u.id,
          label: u.full_name
        }))

        if (self.mode === self.$constants.FORM_MODE.CREATE) {
          self.data.assigned_to = {
            id: self.profile.data.id.toString(),
            label:
              self.profile.data.first_name + ' ' + self.profile.data.last_name
          }
        }
      })

      this.$api.get('dictionaries/products').then(response => {
        self.dropdown('Product Name').options = response.map(p => ({
          id: p.id,
          label: p.name,
          unit_price: p.unit_price
        }))
      })

      this.$api
        .post('dictionaries/countries/mapped', {
          ignore_restrictions: true
        })
        .then(response => {
          self.districts = response

          self.controls.country.options = self.$helpers.getDistinctArray(
            response,
            'country',
            'country',
            'id',
            'label'
          )
          self.controls.state.options = self.$helpers.getDistinctArray(
            response.filter(s => s.country === self.data.country.id),
            'state',
            'state',
            'id',
            'label'
          )
          self.controls.city.options = self.$helpers.getDistinctArray(
            response.filter(s => s.state === self.data.state.id),
            'city',
            'city',
            'id',
            'label'
          )
        })
    },
    edit () {},

    save (_mode) {
      if (!this.$form.testForm(this)) {
        this.$form.makeToastError('Form contains errors')
        return Promise.resolve(false)
      }

      this.saveInProgress = _mode === 'tabular'
      this.saveAndViewInProgress = _mode === 'view'

      let self = this
      let method = ''

      let url = this.baseApiUrl

      if (this.mode === this.$constants.FORM_MODE.CREATE) {
        method = 'put'
        url = this.baseApiUrl
      }
      if (this.mode === this.$constants.FORM_MODE.EDIT) {
        method = 'put'
        url = `${this.baseApiUrl}/${this.data.id}`
      }

      this.dataTable.dataSet.forEach(p => {
        if (!p.post_data && p.ID !== 'Total_ID') {
          p.post_data = {
            invoice_id: this.id
          }
          p.post_data.product = self.rowDropdown(p, 'Product Name').value
          p.post_data.quantity = p['Quantity']
        }
      })

      this.data.products = this.dataTable.dataSet
        .filter(i => i.ID !== 'Total_ID')
        .map(i => i.post_data)

      this.isLoading = true

      return this.$api[method](url, this.data)
        .then(response => {
          this.isLoading = false
          this.saveInProgress = false
          this.saveAndViewInProgress = false

          this.$form.makeToastInfo(response.message)

          this.$router.currentRoute.params.id = response.id

          return response.id
        })
        .catch(error => {
          this.isLoading = false
          this.saveInProgress = false
          this.saveAndViewInProgress = false

          this.$form.makeToastError(error.message)
          return Promise.resolve(false)
        })
    },
    updateDateField (e) {
      this.updateField(e.id, e.valueSingleAsString, e.mode)
    },
    updateField (field, value, mode) {
      this.$form.updateField(this.baseApiUrl, this, field, value, mode)
    },
    updateSelect (id, value) {
      this.$form.updateField(this.baseApiUrl, this, id, value, this.mode)
    },
    fetchData: async function (id) {
      let self = this

      this.isLoading = true
      return this.$api
        .get(`${this.baseApiUrl}/${id}`)
        .then(response => {
          this.isLoading = false

          if (self.$_.isEmpty(response)) return

          self.data.id = self.id

          self.data.subject = response['Subject']
          self.data.invoice_date = response['Invoice Date']
          self.data.modified_time = response['Modified Time']
          self.data.invoice_no = response['Invoice No']

          self.data.assigned_to = {
            id: response['assigned_to_id'],
            label: response['Assigned To']
          }

          self.data.account = {
            id: response['account_id'],
            label: response['Account Name']
          }

          self.data.description = response['Description']

          self.data.street = response['Street']
          self.data.country = {
            id: response['Country'],
            label: response['Country']
          }
          self.data.state = {
            id: response['State'],
            label: response['State']
          }
          self.data.city = {
            id: response['City'],
            label: response['City']
          }
          self.data.postal_code = response['Postal Code']
          self.data.pobox = response['PO Box']
        })
        .catch(error => {
          this.isLoading = false

          console.log(error)
        })
    },
    drawDataTable: async function () {
      this.dataTable.isLoading = true

      let url = `invoices/${this.id}/products`

      let response = await this.$api.get(url)

      this.rawData = response

      this.dataTable.isLoading = false

      this.dataTable.dataSet = this.rawData

      //create array of selected values for multiselect input
      this.dataTable.dataSet.forEach(row => {
        row.dropdowns = []

        row.dropdowns.push({
          name: 'Product Name',
          value: []
        })

        console.log('ROW:', row)
        let val = {
          id: row['ID'],
          label: row['Product Name'].trim(),
          unit_price: parseFloat(row['Price'])
        }

        this.rowDropdown(row, 'Product Name').value = val
      })

      this.dataTable.dataSet.push({
        ID: 'Total_ID',
        Price: 'Invoice Total:',
        Total: 0
      })

      this.updateTotals()
    },
    addItem () {
      let newproduct = {
        ID: this.$constants.CUSTOM_TABLE.NEW_ROW_ID,
        'Product Name': '',
        Quantity: '1',
        Price: '',
        Total: '',
        dropdowns: [
          {
            name: 'Product Name',
            value: []
          }
        ]
      }

      this.dataTable.isInserting = true

      this.dataTable.dataSet.unshift(newproduct)

      Vue.set(this.dataTable.dataSet, 0, newproduct)

      this.$nextTick(() => {
        if (!this.$refs.dataTable) return

        var _this2 = this.$refs.dataTable.$children[0]

        _this2.editing.push({
          id: this.$constants.CUSTOM_TABLE.NEW_ROW_ID,
          column: 'Product Name',
          originalValue: ''
        })
        _this2.editing.push({
          id: this.$constants.CUSTOM_TABLE.NEW_ROW_ID,
          column: 'Quantity',
          originalValue: ''
        })
        _this2.editing.push({
          id: this.$constants.CUSTOM_TABLE.NEW_ROW_ID,
          column: 'Price',
          originalValue: ''
        })
      })
    },
    saveNewRecord (newrecord) {
      let self = this

      if (this.rowDropdown(newrecord, 'Product Name').value.length === 0) {
        this.$form.makeToastError('Please select product')
        return
      }

      if (parseFloat(newrecord['Quantity']) <= 0) {
        this.$form.makeToastError('Please enter product quantity')
        return
      }

      //hide editable cells

      self.$refs.dataTable.$children[0].editing = []
      newrecord.isInserting = false
      self.dataTable.isInserting = false

      //prepare post request
      let record = self.dataTable.dataSet.find(
        i => i.ID === this.$constants.CUSTOM_TABLE.NEW_ROW_ID
      )
      record['ID'] = (self.dataTable.dataSet.length + 1).toString()
      record.post_data = {
        invoice_id: this.id
      }
      record.post_data.product = this.rowDropdown(
        newrecord,
        'Product Name'
      ).value
      record.post_data.quantity = newrecord['Quantity']

      if (this.mode === this.$constants.FORM_MODE.VIEW) {
        this.$api
          .post(`invoices/${this.id}/products`, record.post_data)
          .then(response => {
            newrecord.isInserting = false

            self.dataTable.isInserting = false

            this.$form.makeToastInfo(response.message)

            self.drawDataTable()
          })
          .catch(function (error) {
            this.$form.makeToastError(error.message)

            newrecord.isInserting = false

            self.dataTable.isInserting = false

            self.revertNewRecord()

            self.drawDataTable()
          })
      }
    },
    revertNewRecord () {
      this.dataTable.dataSet.splice(0, 1)

      this.$refs.dataTable.$children[0].editing = []

      this.dataTable.isInserting = false
    },
    deleteItem: async function (id) {
      let row = this.dataTable.dataSet.find(item => item.ID === id)

      let confirm = await this.$form.showConfirmation(
        `Product '${row['Product Name']}' will be removed from invoice. Do you want to proceed?`
      )

      if (!confirm) return

      let self = this

      this.dataTable.dataSet = this.dataTable.dataSet.filter(i => i.ID !== id)
      this.updateTotals()

      if (this.mode === this.$constants.FORM_MODE.VIEW) {
        this.$api
          .delete(`invoices/${id}/products`, {
            invoice_id: this.id,
            product_id: row.ID
          })
          .then(() => {
            this.$form.makeToastInfo('Product removed')

            self.drawDataTable()
          })
          .catch(response => {
            console.log(response)

            this.$form.makeToastError(response.data.message)

            self.drawDataTable()
          })
      }
    },

    validateCell (field, value) {
      if (field === 'Product Name' && value.trim() === '') {
        this.$form.makeToastError('Please select product')
        return false
      }

      if (field === 'Quantity' && parseFloat(value) <= 0) {
        this.$form.makeToastError('Please select product quantity')
        return false
      }

      return true
    },
    onChangeSelect (_value, _id) {
      let id = _id.split(':')[0]
      let column = _id.split(':')[1]

      let idx = this.dataTable.dataSet.findIndex(item => item.ID === id)

      let item = this.dataTable.dataSet[idx]

      let dropdown = item.dropdowns.find(n => n.name === column)

      dropdown.value = _value

      if (this.dropdown(column).multiple) {
        item[column] = dropdown.value.map(e => e.label).join(', ')
      } else {
        item[column] = dropdown.value.label
      }

      if (column === 'Product Name') {
        item['Price'] = dropdown.value.unit_price
      }

      Vue.set(this.dataTable.dataSet, idx, item)

      this.updateTotals()
    },
    updateTotals () {
     
      let total = 0

      this.dataTable.dataSet
        .filter(i => i.ID !== 'Total_ID')
        .forEach(row => {
          row['Total'] = parseFloat(row['Price']) * parseFloat(row['Quantity'])

          total += parseFloat(row['Total'])
        })

      let row_total = this.dataTable.dataSet.find(i => i.ID === 'Total_ID')

      row_total['Total'] = total
    },
    onInlineTableUpdate (payload) {
      let self = this

      let data = {
        id: this.id,
        product_id: payload.row['ID']
      }

      if (payload.column === 'Product Name') {
        data.product = this.rowDropdown(payload.row, 'Product Name').value
      }

      if (payload.column === 'Quantity') {
        data.quantity = payload['newVal']
      }

      self.onColumnUpdate(data.product_id, payload.column, payload['newVal'])

      if (this.mode === this.$constants.FORM_MODE.VIEW) {
        this.$api
          .put(`invoices/${this.id}/products`, data)
          .then(response => {
            self.drawDataTable()

            this.$form.makeToastInfo(response.message)
          })
          .catch(function (error) {
            this.$form.makeToastError(error.message)

            self.drawDataTable()
          })
      }
    },
    onColumnUpdate (id, column, value) {
      let idx = this.dataTable.dataSet.findIndex(item => item['ID'] === id)

      let item = this.dataTable.dataSet[idx]

      item[column] = value

      Vue.set(this.dataTable.dataSet, idx, item)

      this.updateTotals()
    },
    onFilter () {
      this.dataTable.totalRecords = this.$refs.dataTable.data.length
    },
    onItemDropdownOpen () {
      this.isDropdownOpen = true
    },
    onItemDropdownClose () {
      this.isDropdownOpen = false
    }
  },
  watch: {
    'data.country': function (newVal) {
      if (newVal.id) {
        this.controls.state.options = this.$helpers.getDistinctArray(
          this.districts.filter(s => s.country === newVal.id),
          'state',
          'state',
          'id',
          'label'
        )
      }
    },
    'data.state': function (newVal) {
      if (newVal.id) {
        this.controls.city.options = this.$helpers.getDistinctArray(
          this.districts.filter(s => s.state === newVal.id),
          'city',
          'city',
          'id',
          'label'
        )
      }
    }
  }
}
</script>

<style scoped>
/*account preview modal resize */
.modal-lg {
  max-width: 70vw !important;
  max-height: 70vh !important;
}

table .multiselect__content-wrapper {
  position: relative !important;
}

table .multiselect {
  width: 300px;
}

::v-deep .table td {
  padding: 0.3rem;
}

::v-deep .table-responsive {
  overflow: visible !important;
}

::v-deep table {
  overflow: visible !important;
}
</style>
