var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('b-card', [_c('b-tabs', {
    attrs: {
      "content-class": "mt-3",
      "justified": ""
    }
  }, [_c('b-tab', {
    attrs: {
      "title": "Overview",
      "active": ""
    }
  }, [_c('form', [_c('h5', [_vm._v(" Invoice details "), _vm.isLoading ? _c('b-spinner', {
    staticClass: "mb-1",
    attrs: {
      "small": "",
      "type": "grow"
    }
  }) : _vm._e()], 1), _c('hr'), _c('b-row', [_c('b-col', {
    attrs: {
      "xl": "3",
      "lg": "6",
      "md": "6",
      "sm": "12",
      "xs": "12"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.subject.id,
      "value": _vm.data.subject,
      "label": _vm.controls.subject.label,
      "readonly": false,
      "mode": _vm.mode,
      "required": true
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "xl": "3",
      "lg": "6",
      "md": "6",
      "sm": "12",
      "xs": "12"
    }
  }, [_c('inline-date-picker', {
    attrs: {
      "id": _vm.controls.invoice_date.id,
      "value-single": _vm.data.invoice_date,
      "label": _vm.controls.invoice_date.label,
      "readonly": _vm.controls.invoice_date.readonly,
      "mode": _vm.mode
    },
    on: {
      "changed": _vm.updateDateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "xl": "3",
      "lg": "6",
      "md": "6",
      "sm": "12",
      "xs": "12"
    }
  }, [_c('inline-select', {
    attrs: {
      "id": _vm.controls.assigned_to.id,
      "value": _vm.data.assigned_to,
      "label": _vm.controls.assigned_to.label,
      "allow-empty": false,
      "readonly": false,
      "options": _vm.controls.assigned_to.options,
      "mode": _vm.mode,
      "required": true
    },
    on: {
      "changed": _vm.updateSelect
    }
  })], 1), _c('b-col', {
    attrs: {
      "xl": "3",
      "lg": "6",
      "md": "6",
      "sm": "12",
      "xs": "12"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.invoice_no.id,
      "value": _vm.data.invoice_no,
      "label": _vm.controls.invoice_no.label,
      "readonly": true,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "xl": "3",
      "lg": "6",
      "md": "6",
      "sm": "12",
      "xs": "12"
    }
  }, [_c('inline-select', {
    attrs: {
      "id": _vm.controls.account.id,
      "link": _vm.controls.account.link,
      "value": _vm.data.account,
      "label": _vm.controls.account.label,
      "allow-empty": false,
      "readonly": false,
      "options": _vm.controls.account.options,
      "mode": _vm.mode,
      "link-mode": true,
      "loading": _vm.controls.account.loading,
      "async": true,
      "required": true
    },
    on: {
      "async-search": _vm.onAccountSearch,
      "link-clicked": _vm.onAccountLinkClicked,
      "changed": _vm.updateSelect
    }
  })], 1), _c('b-col', {
    attrs: {
      "xl": "3",
      "lg": "6",
      "md": "6",
      "sm": "12",
      "xs": "12"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.modified_time.id,
      "value": _vm.data.modified_time,
      "label": _vm.controls.modified_time.label,
      "readonly": true,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "6",
      "md": "6",
      "sm": "12",
      "xs": "12"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.description.id,
      "value": _vm.data.description,
      "label": _vm.controls.description.label,
      "readonly": false,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1)], 1), _c('h5', [_vm._v("Address details")]), _c('hr'), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.street.id,
      "value": _vm.data.street,
      "label": _vm.controls.street.label,
      "readonly": false,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3"
    }
  }, [_c('inline-select', {
    attrs: {
      "id": _vm.controls.country.id,
      "value": _vm.data.country,
      "label": _vm.controls.country.label,
      "readonly": _vm.controls.country.readonly,
      "options": _vm.controls.country.options,
      "allow-empty": false,
      "mode": _vm.mode,
      "required": true
    },
    on: {
      "changed": _vm.updateSelect
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3"
    }
  }, [_c('inline-select', {
    attrs: {
      "id": _vm.controls.state.id,
      "value": _vm.data.state,
      "label": _vm.controls.state.label,
      "readonly": _vm.controls.state.readonly,
      "options": _vm.controls.state.options,
      "allow-empty": false,
      "taggable": true,
      "mode": _vm.mode,
      "required": true
    },
    on: {
      "changed": _vm.updateSelect
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3"
    }
  }, [_c('inline-select', {
    attrs: {
      "id": _vm.controls.city.id,
      "value": _vm.data.city,
      "label": _vm.controls.city.label,
      "readonly": _vm.controls.city.readonly,
      "options": _vm.controls.city.options,
      "allow-empty": false,
      "taggable": true,
      "mode": _vm.mode,
      "required": true
    },
    on: {
      "changed": _vm.updateSelect
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.postal_code.id,
      "value": _vm.data.postal_code,
      "label": _vm.controls.postal_code.label,
      "readonly": false,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "md": "3"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.pobox.id,
      "value": _vm.data.pobox,
      "label": _vm.controls.pobox.label,
      "readonly": false,
      "mode": _vm.mode,
      "required": false
    },
    on: {
      "changed": _vm.updateField
    }
  })], 1)], 1), _c('hr'), _c('h5', [_vm._v("Item details")]), _c('hr'), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "4"
    }
  }, [_c('b-button-group', {
    staticClass: "p-2"
  }, [_c('button', {
    staticClass: "btn btn-outline-dark btn",
    attrs: {
      "type": "button",
      "disabled": _vm.dataTable.isInserting,
      "title": "Add product"
    },
    on: {
      "click": function click($event) {
        return _vm.addItem();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "plus"
    }
  })], 1)])], 1)], 1), _c('b-row', [_c('b-col', [_c('div', {
    attrs: {
      "id": "card"
    }
  }, [_vm.dataTable.dataSet.length ? _c('v-client-table', {
    ref: "dataTable",
    attrs: {
      "name": "client_table_invoice_submission",
      "options": _vm.dataTable.options,
      "columns": _vm.dataTable.columns
    },
    on: {
      "filter": _vm.onFilter,
      "update": _vm.onInlineTableUpdate
    },
    scopedSlots: _vm._u([_vm._l(_vm.dataTable.options.editableColumns, function (column, index) {
      return {
        key: column,
        fn: function fn(_ref) {
          var row = _ref.row,
            update = _ref.update,
            setEditing = _ref.setEditing,
            isEditing = _ref.isEditing,
            revertValue = _ref.revertValue;
          return [!isEditing() ? _c('span', {
            key: "dts1-".concat(index),
            staticClass: "default-cell",
            on: {
              "click": function click($event) {
                return setEditing(true);
              }
            }
          }, [_vm._v(_vm._s(row[column]))]) : _vm._e(), !isEditing() && !row[column] ? _c('span', {
            key: "dts2-".concat(index),
            staticClass: "pl-5",
            on: {
              "click": function click($event) {
                return setEditing(true);
              }
            }
          }, [_c('b-img')], 1) : _vm._e(), isEditing() ? _c('span', {
            key: "dts3-".concat(index),
            staticClass: "d-flex flex-row align-items-center default-cell"
          }, [isEditing() && !_vm.dropdown(column) ? _c('b-form-input', {
            staticStyle: {
              "width": "150px"
            },
            attrs: {
              "type": "number"
            },
            on: {
              "input": function input($event) {
                return _vm.onColumnUpdate(row['ID'], column, row[column]);
              }
            },
            model: {
              value: row[column],
              callback: function callback($$v) {
                _vm.$set(row, column, $$v);
              },
              expression: "row[column]"
            }
          }) : _vm._e(), isEditing() && _vm.dropdown(column) ? _c('multiselect', {
            attrs: {
              "id": row['ID'] + ':' + column,
              "track-by": "id",
              "label": "label",
              "value": _vm.rowDropdown(row, column).value,
              "options": _vm.dropdown(column).options,
              "show-labels": false,
              "placeholder": "",
              "multiple": _vm.dropdown(column).multiple,
              "allow-empty": _vm.dropdown(column).allowEmpty
            },
            on: {
              "input": _vm.onChangeSelect,
              "open": _vm.onItemDropdownOpen,
              "close": _vm.onItemDropdownClose
            }
          }) : _vm._e(), !_vm.isNewRecord(row) ? _c('span', {
            staticClass: "btn btn-success btn-sm ml-2",
            on: {
              "click": function click($event) {
                if (_vm.validateCell(column, row[column])) {
                  update(row[column]);
                  setEditing(false);
                }
              }
            }
          }, [_c('font-awesome-icon', {
            attrs: {
              "icon": "check"
            }
          })], 1) : _vm._e(), !_vm.isNewRecord(row) ? _c('span', {
            staticClass: "btn btn-danger btn-sm",
            on: {
              "click": function click($event) {
                revertValue();
                setEditing(false);
              }
            }
          }, [_c('font-awesome-icon', {
            attrs: {
              "icon": "ban"
            }
          })], 1) : _vm._e()], 1) : _vm._e()];
        }
      };
    }), {
      key: "Actions",
      fn: function fn(props) {
        return _c('div', {}, [_c('clip-loader', {
          staticClass: "mt-1",
          attrs: {
            "size": 14,
            "color": "#36D7B7",
            "loading": _vm.isNewRecord(props.row) && props.row.isInserting === true
          }
        }), _vm.isNewRecord(props.row) && !props.row.isInserting ? _c('div', {
          staticClass: "btn-group"
        }, [_c('button', {
          staticClass: "btn btn-success btn-sm",
          on: {
            "click": function click($event) {
              return _vm.saveNewRecord(props.row);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "check"
          }
        })], 1), _c('button', {
          staticClass: "btn btn-danger btn-sm",
          on: {
            "click": _vm.revertNewRecord
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "ban"
          }
        })], 1)]) : _vm._e(), props.row.ID !== 'Total_ID' && !_vm.isNewRecord(props.row) ? _c('button', {
          staticClass: "btn btn-danger btn-sm",
          on: {
            "click": function click($event) {
              return _vm.deleteItem(props.row.ID);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "trash"
          }
        })], 1) : _vm._e()], 1);
      }
    }], null, true),
    model: {
      value: _vm.dataTable.dataSet,
      callback: function callback($$v) {
        _vm.$set(_vm.dataTable, "dataSet", $$v);
      },
      expression: "dataTable.dataSet"
    }
  }, [_vm._l(_vm.dataTable.formatColumns, function (column, index) {
    return void 0;
  }), _vm._l(_vm.readOnlyColumns, function (roColumnName) {
    return void 0;
  })], 2) : _vm._e()], 1)])], 1)], 1)]), _vm.mode !== _vm.$constants.FORM_MODE.CREATE ? _c('b-tab', {
    attrs: {
      "title": _vm.controls.tabs.files.title
    }
  }, [_c('files-container', {
    attrs: {
      "module-id": _vm.$route.meta.module.id,
      "entity-id": _vm.id
    },
    on: {
      "loaded": _vm.onFileContainerLoad
    }
  })], 1) : _vm._e()], 1), _c('hr'), _c('form-submission-actions', {
    attrs: {
      "mode": _vm.mode,
      "loading": {
        save: _vm.saveInProgress,
        saveAndView: _vm.saveAndViewInProgress
      },
      "buttons-visibility": {
        previous: _vm.$customTable.getPrevKey(_vm.$route.meta.module, _vm.id),
        next: _vm.$customTable.getNextKey(_vm.$route.meta.module, _vm.id)
      }
    },
    on: {
      "previous-item": function previousItem($event) {
        _vm.$router.push({
          name: _vm.$route.name,
          params: {
            action: _vm.$route.params.action,
            id: _vm.$customTable.getPrevKey(_vm.$route.meta.module, _vm.id)
          }
        });
      },
      "next-item": function nextItem($event) {
        _vm.$router.push({
          name: _vm.$route.name,
          params: {
            action: _vm.$route.params.action,
            id: _vm.$customTable.getNextKey(_vm.$route.meta.module, _vm.id)
          }
        });
      },
      "save": function save($event) {
        _vm.save('tabular').then(function (response) {
          return response ? _vm.$router.push({
            name: 'Invoices'
          }) : false;
        });
      },
      "save-and-view": function saveAndView($event) {
        _vm.save('view').then(function (response) {
          return response ? _vm.$router.push({
            name: 'Invoice submission',
            params: {
              action: 'view',
              id: response
            }
          }) : false;
        });
      },
      "edit": function edit($event) {
        return _vm.$router.push({
          name: 'Invoice submission',
          params: {
            action: 'edit',
            id: _vm.id
          }
        });
      },
      "back": function back($event) {
        return _vm.$router.push(_vm.$store.getters['router/previousRoute']);
      }
    }
  })], 1), _c('b-modal', {
    ref: "account-preview-modal",
    attrs: {
      "centered": "",
      "scrollable": "",
      "size": "lg",
      "title": _vm.accountPreviewModal.title
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('hr'), _c('div', {
          staticClass: "form-row d-flex justify-content-end"
        }, [_c('b-button', {
          staticClass: "m-1",
          attrs: {
            "variant": "outline-dark"
          },
          on: {
            "click": function click($event) {
              return _vm.openAccountInNewWindow(_vm.accountPreviewModal.id);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "share"
          }
        }), _vm._v(" Open in new window ")], 1), _c('b-button', {
          staticClass: "m-1",
          attrs: {
            "variant": "outline-dark"
          },
          on: {
            "click": function click($event) {
              return _vm.closeAccountPreview();
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "times"
          }
        }), _vm._v(" Close ")], 1)], 1)];
      },
      proxy: true
    }])
  }, [_c('b-container', {
    staticClass: "ma-0 pa-0",
    staticStyle: {
      "height": "80vh"
    },
    attrs: {
      "fluid": ""
    }
  }, [_c('AccountSubmissionForm', {
    attrs: {
      "id": _vm.accountPreviewModal.id,
      "action": "preview"
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }